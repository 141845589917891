@import url('https://fonts.googleapis.com/css2?family=Bona+Nova+SC:ital,wght@0,400;0,700;1,400&family=Bree+Serif&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root{
  --anshorange: #d59f5c;
  --pagecolor: #232c24;
  --textcolor: white;
  --homepagetext: white;
  --font: "Poppins", sans-serif;
}
/* @font-face {
  font-family: 'BreeSerif';
  src: url('../public/BreeSerif-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal; 
} */

h1, h2, h3, h4, h5, h6 {
  text-align: center;
  font-family: var(--font);
  color: var(--textcolor);
}

.page-heading {
  color: var(--anshorange); /* Set color for h1 elements */
}
.other-text{
  color: var(--textcolor);
}
p {
  font-family: var(--font); /* Apply custom font to paragraphs */
   color: var(--textcolor);
}
.footer-textH{
  color: var(--homepagetext) !important;
}
.footer-textO{
  color: var(--textcolor) !important;
}
.footer {
  width: 100%;
  text-align: center;
  padding-top: 5vw;
  color: #333;
  font-size: 1.25vw;
}

.footer-home {
  position: fixed;
  bottom: 0;
  background-color: transparent;
  box-shadow: 4px -2px 10px transparent; /* Transparent background for the homepage */
  color: var(--pagecolor) !important; /* White text color for the homepage */
}

.footer-other {
  background-color: var(--pagecolor); /* White background for other pages */
  color: var(--textcolor) !important; /* Default text color for other pages */
}
.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  color: #fff;
  opacity: 0;
  transition: opacity 0.5s ease;
  border-radius: 10px; /* Match the card's border-radius */
}

/* Show the overlay on hover */
.narrative-card-view:hover .card-overlay {
  opacity: 1;
}
/* Narrative card styles */
.narrative-card-view {
  border: none !important;
  color: #EBE9E9!important;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.narrative-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px var(--anshorange)  !important;
}

.card-img-top {
  padding: 0px !important;
  border-radius: 10px !important;
}

.btn-primary {
  color: var(--anshorange)!important;
  background-color: transparent !important;
  border: none !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: transparent !important;
  border: none !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.narrative-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.narrativevideo-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin-bottom: 20px;
}

.narrativevideo-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cred {
  font-size: 24px; /* Adjust font size for p1 */
  text-align: left;
  color: var(--textcolor);
}

.desc {
  font-size: 20px; /* Adjust font size for p1 */
  text-align: left;
  color: var(--textcolor);
}
