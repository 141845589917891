/* style.css or App.css */
@import './Components/Experimental Films/VideoCarousel.css';
body {
  background-color: var(--pagecolor); /* Set default background color for all pages */
}

body.home-page {
  background-color: transparent; /* Transparent background for the homepage */
}

body.no-home-page {
  background-color: var(--pagecolor);
  /* background-color: #ffcf99; White background for all other pages */
}
body.no-home-page {
  padding-top: 100px; /* Adjust this value according to your navbar height */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex: 1;
}

/* Reset padding for scrolling pages */
.App.scroll .main-content {
  padding-bottom: 0;
}
