* {
    margin: 0;
    box-sizing: border-box;
}
.homepage{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    position: relative;
    transition: 0.5s;
}

.video-bg{
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    z-index: -1;
    object-fit: cover;
}
/* .main-content {
    padding-top: 100px; 
    min-height: 100vh; 
  }
.App {
    overflow-y: auto;
    height: 100vh;
  } */