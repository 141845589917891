/* Navbar styles */
.navbar {
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  font-family: var(--font); 
}

.navbar-home .brand {
  color: var(--homepagetext) !important;
}

.navbar-home .navbar-nav .nav-link {
  color: var(--homepagetext) !important;
}
.navbar-home .navbar-nav .nav-link:hover {
  color: var(--anshorange) !important;
}

/* Other page navbar styles */
.navbar-other {
  background-color: var(--pagecolor)!important;
  box-shadow: 0 5px 10px 0 var(--pagecolor); 
}
.navbar-home{
  background-color: transparent;
}
.navbar-other .brand {
  color: var(--textcolor) !important;
}
.resume-link{
  color: var(--textcolor) !important;
}

.resume-link:hover{
  color:var(--anshorange) !important;
}
.navbar-nav .nav-link{
  color: var(--textcolor);
}

.navbar-nav .nav-link:not(.brand):hover{
  color: var(--anshorange); 
}

/* Active link and hover effect for all nav-links */
.navbar-nav .active > .nav-link:not([href="/AnshFilmResume.pdf"]),
.navbar-nav .nav-link.active:not([href="/AnshFilmResume.pdf"]),
.navbar-nav .nav-link.show:not([href="/AnshFilmResume.pdf"]),
.navbar-nav .show > .nav-link:not([href="/AnshFilmResume.pdf"]) {
  color: var(--anshorange);
}
/* Fixed top navbar styles */
.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030;
}
/* Hover effect for Ansh Pathapadu on non-homepage */
.navbar-other .brand:hover .brand-text {
  color: var(--anshorange); 
}
/* Toggle button color for the home page */
.navbar-home .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/* Toggle button color for other pages */
.navbar-other .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}
